import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Pre Open WOD 3`}</strong></p>
    <p>{`Buy In: 50 Double Unders (Scaled and Masters=100 Singles)`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Deadlifts (255/175)(Scaled=205/145)(Masters=185/125)`}</p>
    <p>{`HSPU’s (Scaled & Masters=Hand Release Pushups)`}</p>
    <p>{`Cash Out: 50 Double Unders (Scaled and Masters=100 Singles)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday, January 13th is Cardinal Fitness Day at the Yum!.
 The Cards play Virginia Tech at 4:00pm.  With the following link you
can get discounted tickets as a CrossFit the Ville member!  After the
game there will be a CrossFit wod on the court along with several other
workout sessions.  Lets get a big group together and represent The Ville
at the Yum center!  Family is invited as well.`}</em></strong></p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://fevo.me/fitday"
        }}>{`https://fevo.me/fitday`}</a></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      